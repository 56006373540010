import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var toolmessage = this.props.data.toolmessage;
      var education = this.props.data.education.map(function (education) {
        return <div key={education.school}><h3>{education.school}</h3>
          <p className="info">{education.degree}<br/><em className="date">{education.graduated}</em></p>
          <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function (work) {
        return <div key={work.company}><h3>{work.company}</h3>
          <p className="location">{work.location}</p>
          <p className="info">{work.title}<br/><em className="date">{work.years}</em></p>
          <p>{work.description}</p>
        </div>
      })
      var tools = <div className="tool-grid">
        {this.props.data.tools.length > 0 &&
        this.props.data.tools.map((item, index) => {
          return (
            <div key={item.toLowerCase()}>
              <div className="tool-item">
                {item}
              </div>
            </div>
          );
        })}
      </div>
      var skills = <div className="skill-list">
        {this.props.data.skills.length > 0 &&
        this.props.data.skills.map((item, index) => {
          return (
            <div key={item.toLowerCase()}>
              <div className="skill-item">
                {item}
              </div>
            </div>
          );
        })}
      </div>
    }

    return (
      <section id="resume">

        <div className="row work">

          <div className="three columns header-col">
            <h1><span>Work</span></h1>
          </div>

          <div className="nine columns main-col">
            {work}
          </div>
        </div>


        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                {education}
              </div>
            </div>
          </div>
        </div>


        <div className="row tool">

          <div className="three columns header-col">
            <h1><span>Tools & Languages</span></h1>
          </div>

          <div className="nine columns main-col">
            {tools}
          </div>
        </div>


        <div className="row skill">

          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>

          <div className="nine columns main-col">
            {skills}
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
