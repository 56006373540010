import React, { Component } from 'react';

class About extends Component {
  render() {

    if (this.props.data) {
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var phone = this.props.data.phone;
      var phone2 = this.props.data.phone2;
      var phone_href = this.props.data.phone_href;
      var phone2_href = this.props.data.phone2_href;
      var email = this.props.data.email;
      var mailto = `mailto:${email}`
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src={profilepic} alt="Worawat Kimpara Profile Pic"/>
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{bio}</p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="phone">
                  <a href={phone_href}>{phone}</a><br/>
                  <a href={phone2_href}>{phone2}</a><br/>
                  <a href={mailto}>{email}</a>
                </p>
              </div>
              <div className="columns download">
                <p>
                  <a href={resumeDownload} target="_blank" className="button"><i className="fa fa-download"></i>Download
                    Resume</a>
                </p>
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default About;
