import React, { Component } from 'react';

class Endorsements extends Component {
  render() {

    if(this.props.data){
      var endorsements = this.props.data.endorsements.map(function(endorsements){
        return  <li key={endorsements.user}>
            <blockquote>
              <p>{endorsements.text}</p>
              <cite>{endorsements.user}</cite><br/>
              <div className="source link">
                <p>
                  <a href={endorsements.source} target="_blank" className="button"><i className="fa fa-download"></i>View Endorsement</a>
                </p>
              </div>
            </blockquote>
         </li>
      })
    }

    return (
      <section id="endorsements">
      <div className="text-container">
         <div className="row">

            <div className="two columns header-col">
               <h1><span>Endorsements</span></h1>
            </div>

            <div className="ten columns flex-container">
                  <ul className="slides">
                      {endorsements}
                  </ul>
               </div>
            </div>
         </div>
   </section>
    );
  }
}

export default Endorsements;
